<template>
    <div id="background">
    <div id="continer">
        <div id="widget">
            <div class="title-image">
                <img :src="require('../assets/images/horizontal_logo.png')" width="250" height="50">
            </div>

            <div class="d-flex justify-content-center align-items-center h-75" v-if="isLoading">
                <b-spinner variant="success" label="Spinning"></b-spinner>
            </div>

            <form class="modal-form" @submit.stop.prevent="sendRequestDeposit" v-if="!isLoading">
            <div v-if="!depositDetail" id="deposit-form" class="select-row">
                <div class="select-boxes">
                    <label for="">{{$t("Network")}}</label>
                    <div class="dropdown">
                        <button id="network-dropbtn" class="network-dropbtn dropbtn w-100" type="button" @click="networkDropdown()">
                            <div class="network-dropbtn dropdown-item-content">
                                <img v-if="networkImage" :src="networkImage" width=30 height=30/>
                                <span class="network-dropbtn text-light-gray small ml-2">{{ selectedNetwork }}</span>
                            </div>
                            <div class="network-dropbtn dropdown-arrow">
                                <i class="network-dropbtn fa fa-chevron-down" aria-hidden="true"></i>
                            </div>
                        </button>
                        <div id="network-dropdown" class="dropdown-content">
                            <div class="dropdown-item" v-for="network in networksList" :value="network.number" :key="network.name" @click="selectNetwork(network.number, network.image)">
                                <div class="dropdown-item-content">
                                    <img :src="network.image" width=30 height=30/>
                                    <span class="text-gray small ml-2">{{ network.name.toUpperCase() }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="select-boxes">
                    <label for="">{{$t("Coin")}}</label>
                    <div class="dropdown">
                        <button id="coin-dropbtn" class="coin-dropbtn dropbtn not-allowed w-100" type="button" @click="coinDropdown()">
                            <div class="coin-dropbtn dropdown-item-content">
                                <img v-if="coinImage" :src="coinImage" width=30 height=30/>
                                <span class="coin-dropbtn text-light-gray small ml-2">{{ selectedCoin }}</span>
                            </div>
                            <div class="coin-dropbtn dropdown-arrow">
                                <i class="coin-dropbtn fa fa-chevron-down" aria-hidden="true"></i>
                            </div>
                        </button>
                        <div id="coin-dropdown" class="dropdown-content">
                            <div class="dropdown-item" v-for="coin in coinsList" :value="coin" :key="coin.symbol" @click="selectCoin(coin.api_symbol, coin.symbol, coin.image)">
                                <div class="dropdown-item-content">
                                    <img :src="coin.image" width=30 height=30/>
                                    <span class="text-gray small ml-2">{{ coin.symbol }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="input-box">
                    <b-form-input
                        id="example-input-1"
                        :placeholder="$t(`Enter amount`)"
                        v-model="$v.requestDepositForm.amount.$model"
                        :state="validateState('amount','requestDepositForm')"
                        aria-describedby="input-1-live-feedback"
                    ></b-form-input>
                    <b-form-invalid-feedback id="input-1-live-feedback">{{$t("This is a required field.")}}</b-form-invalid-feedback>
                </div>
                <button type="submit" class="btn submit-btn" :disabled="$v.requestDepositForm.amount.$model < 0">{{0 > $v.requestDepositForm.amount.$model ? "NO" : "Request"}}</button>
            </div>
            </form>

            <div v-if="depositDetail && !isLoading" id="depositDetail">
                <div class="qr-address align-text text-center" v-if="depositDetail.status == 'pending'">
                    <div>{{$t("Deposit")}}</div>
                    <div class="title px-1">
                        {{ depositDetail.amount }}{{symbol}}
                    </div>
                    <div>{{$t("to")}}</div>
                </div>

                <div class="qr-address" v-if="depositDetail.status == 'pending'">
                    <div class="address-text">{{ depositDetail.address.address }}</div>
                    <div class="tooltip__layout">
                        <button class="icon-btn" @click="copyToClip(depositDetail.address.address)">
                            <inline-svg
                                :src="require('../assets/icon/copy.svg')"
                                fill="black"
                                width="18"
                                aria-label="copy"
                            ></inline-svg>
                            <span class="tooltip__content" :id="depositDetail.address.address">{{$t("Copied!")}}</span>
                        </button>
                    </div>
                </div>

                <div class="qr-code" v-if="depositDetail.status == 'pending'">
                    <vue-qr :text="depositDetail.address.address" :size="300"></vue-qr>
                </div>

                <div class="text-center" v-if="depositDetail.status == 'deposited'">
                    <inline-svg
                        :src="require('../assets/icon/checked.svg')"
                        fill="black"
                        height="250"
                        width="250"
                        aria-label="copy"
                    ></inline-svg>
                    <h1 class="text-success">{{$t("Success")}}</h1> 
                </div>

                <div class="mb-3 align-text" v-if="depositDetail.status == 'pending'">
                    <div class="deposit-id">{{$t("Deposit ID")}}: </div>
                    <div class="title px-1">{{ deposit_id }}</div>
                </div>

                <div v-if="depositDetail.status == 'pending'">
                    <div class="disclaimer-text text-red">
                        *{{$t("Please send the exact amount in")}} {{countdownMinutes}}:{{countdownSeconds}}. {{$t("Not doing so will result in a delay of payment, or refund up to 7 working days.")}}
                    </div>
                    <div class="disclaimer-text">
                        **{{$t("Please complete the transfer within the countdown time indicated to ensure the current rate and success of the payment. If the funds are transferred after the time lapsed the payment will automatically cancel and require refreshing or redirected back to merchant page to restart the payment process.")}}
                    </div>
                </div>

                <div class="text-center" v-if="depositDetail.status == 'unmatched' || depositDetail.status == 'unused'">
                    <div class="disclaimer-text text-red">
                        *{{$t("Deposit time has EXPIRED, DO NOT DEPOSIT INTO THIS ADDRESS ANYMORE. Doing so will result in a delay of payment, or refund up to 7 working days.")}}
                    </div>
                    <div class="disclaimer-text">
                        **{{$t("If funds are transferred after the time lapsed, the payment will automatically cancel and require refreshing or redirected back to merchant page to restart the payment process.")}}
                    </div>
                </div>

                <form class="modal-form" @submit.stop.prevent="cancelPayment">
                    <div>
                        <button type="submit" class="btn submit-btn my-3">{{$t("Cancel")}}</button>
                    </div>
                </form>
            </div>
        </div>

    </div>
    </div>
</template>

<script>
import VueQr from 'vue-qr'
import moment from 'moment';
import api from '../helper/api.js';
import InlineSvg from "vue-inline-svg";
import { required } from "vuelidate/lib/validators";

export default {
    components: {
        InlineSvg,
        VueQr
    },

    data() {
        return {
            isLoading: true,
            merchantKey: this.$route.params.merchantKey,
            selectedNetwork: 'Select Network',
            networkImage: null,
            selectedCoin: 'Select Coin',
            coinImage: null,
            networksList: null,
            coinsList: null,
            address: '0x0000000',
            platformId: null,
            deposit_id: null,
            select_network: 'SELECT',
            select_coin: 'SELECT',
            requestDepositForm : {
                network: "Network",
                token: "",
                amount: ""
            },
            symbol: null,
            depositDetail: null,
            countdownMinutes: (0).toString() + 0,
            countdownSeconds: (0).toString() + 0,
            worker: new Worker('/script/worker.js'),

            // depositDetail: {"deposit_id":"web_eaqhn","network":"TRC20","address":{"address":"TCa184KE65n4Ttax36oueu159MgBM65BEh","value":"0","path":3,"label":"12","status":"busy","is_activated":false,"created_at":"2022-07-22T00:23:12.903Z","updated_at":"2022-10-28T03:24:45.213Z","platform":{"id":3,"name":"mkonnhkejy","callback_url":"coco.nut/coconut","status":"active","service_type":"reuse","min_charge":"800","whitelist":["1.1.1.1"],"created_at":"2022-07-14T02:44:41.604Z","updated_at":"2022-10-18T10:19:58.110Z"}},"amount":"10.00","token":{"address":"TXLAQ63Xg1NAzckPwKHvzw7CSEmLMEqcdj","name":"USD Tether","symbol":"USDT","decimal":6,"price":"1.0000","status":"active","volume_decimal":2,"price_decimal":2,"api_symbol":"USDT","gas_fee_rate":"1.0000","image":"https://bv2.koinage.cc/trx/tokens/image/USDT.png","created_at":"2022-07-01T07:24:34.847Z","updated_at":"2022-10-14T07:51:34.785Z"},"status":"pending","start":"2022-10-28T03:24:45.000Z","end":"2022-10-28T03:39:45.000Z","countdown":845,"redirect_url":null},
        }
    },
    validations: {
        requestDepositForm: {
            network: {
                required
            },
            token: {
                required
            },
            amount: {
                required,
                Number
            }
        }
    },

    async mounted() {
        // this.isLoading = true;
        await this.fetchNetworksList();
    },

    methods: {
        closeOpenedDropdown(btn, idName) {
            window.onclick = function (event) {
                if (!event.target.classList.contains(btn)) {
                    var dropdowns = document.getElementById(idName)
                    if (dropdowns.classList.contains('show')) {
                        dropdowns.classList.remove('show')
                    }
                }
            }
        },

        validateState(name,form) {
            const { $dirty, $error } = this.$v[form][name];
            return $dirty ? !$error : null;
        },
        async copyToClip(address) {
            await this.$copyText(address).then(function () {
                var x = document.getElementById(address);
                x.classList.add("show");
                setTimeout(function () {
                    x.classList.remove("show");
                }, 1000);
            })
        },

        async fetchNetworksList(){
            try{
                const response = await api.apiCall('api','GET',`/networks/verifyMerchant/${this.merchantKey}`);
                this.networksList = response.message.data.network;
                this.platformId = response.message.data.platformId;
                this.isLoading = false;
            }catch(error){
                this.$swal('Error', 'Invalid merchant key','error');
            }
        },

        networkDropdown() {
            document.getElementById("coin-dropdown").classList.remove('show')
            document.getElementById("network-dropdown").classList.toggle("show");
            this.closeOpenedDropdown('network-dropbtn', 'network-dropdown')
        },

        async selectNetwork(index, image){
            document.getElementById("coin-dropbtn").classList.remove('not-allowed')
            this.requestDepositForm.network = this.networksList[index]['name']
            this.selectedNetwork = this.networksList[index]['name'].toUpperCase()
            this.networkImage = image
            this.coinsList = this.networksList[index]['tokens']
            this.selectedCoin = 'Select Coin'
            this.coinImage = null
        },

        coinDropdown() {
            if (this.requestDepositForm.network != 'Network') {
                document.getElementById("coin-dropdown").classList.toggle("show");
                this.closeOpenedDropdown('coin-dropbtn', 'coin-dropdown')
            }
        },

        async selectCoin(api_symbol, symbol, image){
            this.requestDepositForm.token = api_symbol
            this.selectedCoin = symbol
            this.coinImage = image
        },

        async sendRequestDeposit() {
            // console.log(this.requestDepositForm.amount)

            this.isLoading = true
            this.$v.requestDepositForm.$touch();
            if (this.$v.requestDepositForm.$anyError) {
                console.log('validate fail')
                // this.$swal({
                //     text: "validate fail",
                //     icon: 'error',
                //     // confirmButtonColor: '#33AD4A',
                // })
                this.isLoading = false
                return;
            }

            if(this.requestDepositForm.amount <= 0){
                // this.$swal({
                //     text: "Amount cannot less than 0",
                //     icon: 'error',
                //     // confirmButtonColor: '#33AD4A',
                // })
                return;
            }

            // const random = "web_" + Math.random().toString(36).substring(2,7);
            // const date = new Date()
            const random = "wdg_" + this.requestDepositForm.token + new Date();
            this.deposit_id = random;

            const params = {
                "merchant_key": this.merchantKey,
                "api_symbol": this.requestDepositForm.token,
                "amount": parseFloat(this.requestDepositForm.amount),
                "deposit_id": random
            }

            console.log(params)

            try{
                const response = await api.apiCall(this.requestDepositForm.network,'POST','/deposit-request/generate-request',params);

                if((response.status).toUpperCase()=="SUCCESS") {
                    // this.$swal({
                    //     text: "Deposit request successfully",
                    //     icon: 'success',
                    //     confirmButtonColor: '#33AD4A',
                    // });
                    
                    // this.$refs['request-deposit-modal'].hide();
                    // this.requestDepositForm.amount = "";
                    // this.fetchRequestDeposits();
                }
                console.log(response)
            }catch(error){
                // this.$swal('Error',error.message,'error');
                console.error(error);
            }

            try{
                const response = await api.apiCall('trx','GET',`/deposit-request/payment/${this.platformId}/${random}`);
                this.depositDetail = response.message.data;
                this.symbol = response.message.data.token.symbol;
                this.isLoading = false;
            }catch(error){
                console.error(error);
            }

            if(this.depositDetail.status != 'pending' || !this.depositDetail.countdown){
                return;
            }

            let countdown = setInterval(()=>{
                let now = moment().format("x");
                let expire_date = moment(this.depositDetail.end).format('x');

                let timeLeft = expire_date - now*1;

                if(timeLeft <= 0){
                    clearInterval(countdown);
                }

                this.countdownMinutes = moment.duration(timeLeft).minutes() < 10 ? 0 + (moment.duration(timeLeft).minutes()).toString() : moment.duration(timeLeft).minutes();
                this.countdownSeconds = moment.duration(timeLeft).seconds() < 10 ? 0 + (moment.duration(timeLeft).seconds()).toString() : moment.duration(timeLeft).seconds();
                // this.countdownSeconds = moment.duration(timeLeft).seconds();
            },1000);


            if(window.Worker){
                this.worker.postMessage('');

                this.worker.onmessage = async()=>{
                    try{
                        const response = await api.apiCall('trx','GET',`/deposit-request/payment/${this.platformId}/${random}`);
                        this.depositDetail = response.message.data;

                        if(this.depositDetail.status != 'pending'){
                            this.worker.terminate();
                            clearInterval(countdown);
                        }
                    }catch(error){
                        console.error(error);
                    }
                }
            }
        },

        async cancelPayment(){
            this.depositDetail = null
            this.worker.terminate();
        },

    }
}
</script>

<style>
@import '../css/styles.css';
body {
    background-color: whitesmoke;
}

#background {
    display: flex;
    justify-content: center;
    height: 100%;
    /* background-color: whitesmoke; */
}

#widget {
    /* max-width: 480px;
    max-height: 660px; */
    height: 660px;
    width: 400px;
    /* height: 100%;
    width: 100%; */
    box-shadow: 0 2px 10px 0 rgb(0 0 0 / 10%);
    border-radius: 30px;
    margin: auto;
    background-color: white;
    overflow-y:auto;
}

.deposit {
    height: 100%;
    overflow: scroll;
}

/* Hide scrollbar for Chrome, Safari and Opera */
#widget::-webkit-scrollbar {
    width: 5px;
    /* display: none; */
}

#widget::-webkit-scrollbar-thumb {
    background: #888; 
    border-radius: 50px;
}

/* Handle on hover */
#widget::-webkit-scrollbar-thumb:hover {
    background: #555; 
}

/* Hide scrollbar for IE, Edge and Firefox */
.deposit {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

.deposit-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* height: 90vh; */
}

.title-image {
    display: flex;
    justify-content: center;
    margin: auto;
    padding: 1.3rem;
}

.option-image {
    width: 180px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.qr-code {
    display: flex;
    margin: auto;
}

.qr-address {
    display: flex;
    justify-content: center;
    flex-direction: row;
    align-items: center;
}

.align-text {
    display: flex;
    flex-direction: row;
    justify-content: center;
    line-height: 20px;
    /* margin: auto; */
}

.address-text {
    font-size: 15px;
    padding: 8px 6px;
}

.text-red {
    color: red;
}

.disclaimer-text {
    margin: auto;
    max-width: 360px;
    width: 80%;
    text-align: justify;
    text-justify: inter-word;
    font-weight: 500;
}

.select-row {
    display: flex;
    flex-direction: column;
    padding: 10px;
}

.select-boxes {
    display: flex;
    flex-direction: column;
    margin: 6px;
}

.input-box {
    margin: 13px 6px;
}

select {
    width: 100%;
    padding: 0.375rem 0.75rem;
    border-radius: 30px;
}

option {
    padding: auto;
    /* height: 10px; */
}

#network-dropbtn{
    z-index: 40;
}
#network-dropdown{
    z-index: 30;
}
#coin-dropbtn{
    z-index: 20;
}
#coin-dropdown{
    z-index: 10;
}
/* Dropdown Button */
.dropbtn {
    background-color: white;
    border: 1px solid var(--secondary);
    border-radius: 20px;
    color: var(--primary);
    padding: 0.6rem 0.75rem;
    width: 100%;
    font-size: 16px;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: center;
    position: relative;
    /* align-items: center;  */
    z-index: 10;   
}

.dropdown-arrow {
    position: absolute;
    right: 5%;
}

/* The container <div> - needed to position the dropdown content */
.dropdown {
    position: relative;
    display: inline-block;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
    display: none; 
    position: absolute;
    background-color: var(--light);
    box-shadow: 0px 8px 16px 0px rgb(0 0 0 / 20%);
    width: 100%;
    top: 60%;
    padding-top: 30px;
    border-radius: 0px 0px 20px 20px;
}

.dropdown-item {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px;
    color: var(--primary);
}

.dropdown-item-content {
    width: 180px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.dropdown-item:hover {
    color: var(--primary);
    background-color: var(--primary-10);
}

.dropdown-item:hover .text-gray{
    color: var(--primary);
}

.show {
    display: block;
}

.not-allowed {
    cursor: not-allowed !important;
}


/* fade animation */
#depositDetail {
    animation: fadein 2s;
    -moz-animation: fadein 2s; /* Firefox */
    -webkit-animation: fadein 2s; /* Safari and Chrome */
    -o-animation: fadein 2s; /* Opera */
}

@keyframes fadein {
    from {
        opacity:0;
    }
    to {
        opacity:1;
    }
}
@-moz-keyframes fadein { /* Firefox */
    from {
        opacity:0;
    }
    to {
        opacity:1;
    }
}
@-webkit-keyframes fadein { /* Safari and Chrome */
    from {
        opacity:0;
    }
    to {
        opacity:1;
    }
}
@-o-keyframes fadein { /* Opera */
    from {
        opacity:0;
    }
    to {
        opacity: 1;
    }
}

</style>